const duration = {
  micro: 200,
  short: 400,
  medium: 800,
  long: 1600,
  longer: 2000,
};

export const parentAnimation = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    staggerChildren: 100,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
  initialPose: 'hidden',
};

export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: duration.longer, delay: duration.micro },
    },
  },
  initialPose: 'hidden',
};

export const slideInBottom = {
  hidden: {
    y: 40,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      opacity: { duration: duration.short, delay: duration.micro },
      default: { ease: [0.2, 1, 0.2, 1], duration: duration.long, delay: duration.micro },
    },
  },
  initialPose: 'hidden',
};

export const bladesTop = {
  hidden: {
    y: -100,
    skewY: 8,
    opacity: 0,
  },
  visible: {
    y: 0,
    skewY: 0,
    opacity: 1,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: duration.long, delay: 800 },
    },
  },
  initialPose: 'hidden',
};

export const sloganTop = {
  hidden: {
    y: -100,
    rotate: 180,
    opacity: 0,
  },
  visible: {
    y: 0,
    rotate: 180,
    opacity: 1,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 3000, delay: 1400 },
    },
  },
  initialPose: 'hidden',
};

export const pull = {
  hoverable: true,
  init: {
    x: 0,
  },
  hover: {
    x: 8,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
};

export const pullChild = {
  init: {
    x: 0,
  },
  hover: {
    x: 8,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
};

export const drag2 = {
  init: {
    x: 0,
  },
  hover: {
    x: -6,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
};

export const pull2 = {
  init: {
    x: 0,
  },
  hover: {
    x: 0,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
};

export const pull4 = {
  init: {
    x: 0,
  },
  hover: {
    x: 3,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
};

export const scale = {
  hoverable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
    transition: {
      default: { ease: [0.2, 1, 0.2, 1], duration: 500 },
    },
  },
};

export const pop = {
  hidden: {
    rotate: 0,
    scale: 0,
  },
  visible: {
    rotate: -15,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 120,
      delay: 200,
    },
  },
  initialPose: 'hidden',
};
