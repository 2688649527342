import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

const Switcher = (props) => {
  const {
    data: {
      allContentJson: {
        edges,
      },
    },
    className,
  } = props;

  return (
    <Container>
      <List className={className}>
        {edges.map(({ node }) => (
          <li key={node.id}>
            <StyledLink to={node.fields.slug} color={node.primaryColor} activeClassName="is-active">
              {node.title}
            </StyledLink>
          </li>
        ))}
      </List>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  padding: 5px 0 0;
  justify-content: space-between;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  li {
    flex: 1 0 auto;
  }
`;

const StyledLink = styled(Link)`
  padding: 0.6rem 1rem 0.5rem;
  display: block;
  text-align: center;
  font-size: 0.65rem;
  text-transform: uppercase;
  line-height: 1.5;
  font-family: var(--font-family-medium);
  color: #fff;
  flex-direction: column;
  align-items: center;
  margin: 0;
  background: #2e2e2e;
  border-top: 5px solid ${({ color }) => color};

  &:hover {
    filter: brightness(115%);
    color: #fff;
    text-decoration: none;
  }

  &.is-active {
    box-shadow: 0 -5px 0 ${({ color }) => color};
    filter: brightness(110%);
  }

  @media (min-width: 768px) {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
`;

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentJson {
          edges {
            node {
              id
              title
              primaryColor
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => <Switcher data={data} {...props} />}
  />
);
