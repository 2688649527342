import React from 'react';
import styled from 'styled-components';
import { Logo } from './svg-sprite';
import ArrowLink from './arrow-link';

const Header = (props) => {
  const { color } = props;
  return (
    <Container>
      <Wrap>
        <LogoWrap href="https://sawdustartfestival.org/">
          <LogoImg />
          <span className="visually-hidden">Sawdust Art Festival</span>
        </LogoWrap>
        <Dates>
          <li>June 28 — Sept 1, 2019</li>
          <LeadDot color={color} />
          <li>Laguna Beach, CA</li>
        </Dates>
        <BuyTickets
          color="#fff"
          text="Buy Tickets"
          href="https://sawdustartfestival.org/tickets/"
        />
      </Wrap>
    </Container>
  );
};

const Container = styled.header`
  padding: 0 0.75rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const Wrap = styled.div`
  margin: 0 auto;
  padding: 1rem 0;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (min-width: 375px) {
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 768px) {
    padding: 2rem 0;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Dates = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0.65rem;
  text-transform: uppercase;
  line-height: 1.1;
  font-family: var(--font-family-medium);
  color: #fff;
  order: 1;

  > li + li {
    @media (min-width: 810px) {
      margin-left: 1rem;
    }
  }

  @media (min-width: 768px) {
    order: 2;
    margin-left: 1rem;
    font-size: 0.75rem;
  }

  @media (min-width: 810px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1140px) {
    margin-left: 2.5rem;
  }
`;

const LeadDot = styled.li`
  display: none;

  @media (min-width: 810px) {
    border-radius: 6px;
    display: block;
    width: 6px;
    height: 6px;
    background-color: ${props => props.color};
  }
`;

const LogoWrap = styled.a`
  order: 3;
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;

  @media (min-width: 768px) {
    margin-top: 0;
    order: 1;
    flex: 0 auto;
  }
`;

const LogoImg = styled(Logo)`
  flex: 1 0 100%;
  width: 146px;
  height: 62px;
`;

const BuyTickets = styled(ArrowLink)`
  margin-top: 0.5rem;
  order: 2;

  @media (min-width: 375px) {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    order: 3;
    margin-left: auto;
  }
`;

export default Header;
