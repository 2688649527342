import React from 'react';
import styled from 'styled-components';
import { Offset } from '../elements/layout';

const Footer = () => (
  <Container>
    <Offset>
      <Copyright>
        <span>Copyright 2019 <a href="https://sawdustartfestival.org/">Sawdust Art Festival</a>.</span>
        <span>All rights reserved.</span>
      </Copyright>
    </Offset>
  </Container>
);

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Copyright = styled.p`
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }

  span {
    margin-right: 0.5rem;
  }
`;

export default Footer;
