import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = (props) => {
  const {
    data: {
      desktop: {
        childImageSharp: {
          fluid,
        },
      },
    },
    children,
    className,
  } = props;
  return (
    <StyledBackgroundSection
      Tag="div"
      className={className}
      fluid={fluid}
      backgroundColor="#333"
    >
      {children}
    </StyledBackgroundSection>
  );
};

const StyledBackgroundSection = styled(BackgroundImage)`
  width: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-bg.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <BackgroundSection data={data} {...props} />}
  />
);
