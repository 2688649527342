import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const animation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(120%); }
  50.01% { transform: translateX(-100%); }
  to { transform: translateX(0);
}
`;

const animationRule = css`
  ${animation} 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000);
`;

const StyledButton = styled.a`
  position: relative;
  text-transform: uppercase;
  font-size: 0.65rem;
  line-height: 1.1;
  font-family: var(--font-family-headings);
  display: flex;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  padding: 1rem 1.5rem;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow:
    inset 3px 3px ${props => props.backgroundColor},
    inset 6px 6px ${props => props.primaryColor},
    3px 3px ${props => props.backgroundColor},
    6px 6px ${props => props.primaryColor};

  span {
    margin-right: 0.75rem;
    margin-top: 3px;
    transition: margin 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:hover span {
    margin-right: 1.5rem;
  }

  &:hover {
    text-decoration: none;
    color: ${props => props.primaryColor};
    background-color: #fff;
    box-shadow:
      3px 3px ${props => props.backgroundColor},
      6px 6px ${props => props.primaryColor};
  }

  &:hover path {
    text-decoration: none;
    animation: ${animationRule};
  }

  @media (min-width: 768px) {
    font-size: 0.75rem;
  }
`;

const Button = React.forwardRef((props, ref) => {
  const { primaryColor, backgroundColor, className } = props;
  return (
    <StyledButton
      className={className}
      primaryColor={primaryColor}
      backgroundColor={backgroundColor}
      href="https://sawdustartfestival.org/tickets/"
      ref={ref}
    >
      <span>Buy Tickets</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="15">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M19.17 6.21l-4.66-4.65L15.93.14 23 7.21l-7.07 7.07-1.42-1.41 4.66-4.66H0v-2z"
        />
      </svg>
    </StyledButton>
  );
});

export default Button;
