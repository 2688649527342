import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { Facebook, Twitter, Instagram } from './svg-sprite';

const parentProps = {
  hidden: {},
  visible: {
    staggerChildren: 100,
  },
};

const childrenProps = {
  hidden: {
    y: 10,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      default: { ease: [0.190, 1.000, 0.220, 1.000], duration: 600 },
    },
  },
};

const Container = styled(posed.div(parentProps))`
  display: flex;
`;

const Icon = styled(posed.a(childrenProps))`
  margin: 0 0.25rem;
  color: #fff;

  &:hover {
    color: ${props => props.primaryColor};
    transform: scale(1.05);
  }
`;

const SocialLinks = (props) => {
  const { primaryColor } = props;
  return (
    <Container>
      <Icon primaryColor={primaryColor} target="_blank" rel="noopener noreferrer" href="https://facebook.com">
        <Facebook />
        <span className="visually-hidden">Facebook</span>
      </Icon>
      <Icon primaryColor={primaryColor} target="_blank" rel="noopener noreferrer" href="https://twitter.com">
        <Twitter />
        <span className="visually-hidden">Twitter</span>
      </Icon>
      <Icon primaryColor={primaryColor} target="_blank" rel="noopener noreferrer" href="https://instagram.com">
        <Instagram />
        <span className="visually-hidden">Instagram</span>
      </Icon>
    </Container>
  );
};

export default SocialLinks;
