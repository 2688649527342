import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../utils/animation';

const Meta = (props) => {
  const {
    color,
    className,
    children,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <P ref={ref} pose={inView ? 'visible' : 'hidden'} className={className} color={color}>{children}</P>
      )}
    </InView>
  );
};

const P = styled(posed.p(slideInBottom))`
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0.75rem;
  text-shadow: 2px 2px ${({ color }) => color};
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1rem;
    letter-spacing: 3px;
  }
`;

export default Meta;
