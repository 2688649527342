import React from 'react';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import styled from 'styled-components';
import { Offset } from '../elements/layout';
import { parentAnimation, slideInBottom } from '../utils/animation';
import SocialLinks from './social-links';
import { Tickets } from './svg-sprite';
import Button from './button';

const Container = styled(posed.div(parentAnimation))`
  margin-top: -10rem;
  margin-bottom: 2rem;
`;

const Inner = styled.div`
  background-color: #2e2e2e;
  position: relative;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #2e2e2e;
    position: absolute;
    top: 0;
    left: 100%;
  }

  @media (min-width: 415px) {
    padding: 3rem 1rem;
  }

  @media (min-width: 768px) {
    padding: 4.5rem 2.5rem 3.5rem;
    flex-direction: row;
    align-items: center;
  }
`;

const TicketsWrap = styled(posed.div(slideInBottom))`
  position: absolute;
  top: -17px;
  left: 1rem;
  display: block;

  svg {
    display: block;
    width: 230px;
    height: 35px;
  }

  @media (min-width: 415px) {
    top: -22px;
    left: 1rem;
    display: block;

    svg {
      display: block;
      width: 290px;
      height: 45px;
    }
  }

  @media (min-width: 768px) {
    top: -34px;
    left: 3.75rem;
    display: block;
    width: 475px;
    height: 72px;

    svg {
      width: 475px;
      height: 72px;
    }
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    margin-right: 5rem;
  }
`;

const Dates = styled.div`
  font-size: 0.65rem;
  text-transform: uppercase;
  line-height: 1.5;
  font-family: var(--font-family-headings);
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.75rem 0 1.5rem;

  @media (min-width: 768px) {
    font-size: 0.75rem;
    margin-bottom: 0;
  }
`;

const PosedSpan = posed.span(slideInBottom);

const PosedButton = posed(Button)(slideInBottom);

const CallToAction = (props) => {
  const { primaryColor, className } = props;
  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container ref={ref} pose={inView ? 'visible' : 'hidden'}>
          <Offset>
            <Inner>
              <TicketsWrap className={className}>
                <Tickets />
              </TicketsWrap>
              <Details>
                <SocialLinks primaryColor={primaryColor} />
                <Dates>
                  <PosedSpan>Open Daily</PosedSpan>
                  <PosedSpan>June 28 — Sept 1, 2019</PosedSpan>
                  <PosedSpan>Laguna Beach, CA</PosedSpan>
                </Dates>
              </Details>
              <PosedButton
                primaryColor={primaryColor}
                backgroundColor="#2e2e2e"
              />
            </Inner>
          </Offset>
        </Container>
      )}
    </InView>
  );
};

export default CallToAction;
