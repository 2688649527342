/**
 * Layout component.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import '../styles/fonts.css';
import '../styles/root.css';
import '../styles/reboot.css';

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`;

const Layout = ({ children }) => (
  <Container>
    {(children)}
  </Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
