/**
 * General Elements.
 */
import styled from 'styled-components';
import posed from 'react-pose';
import { slideInBottom } from '../utils/animation';

export const Offset = styled.div`
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 1.5rem;

  @media (min-width: 768px) {
    padding-left: 5rem;
  }
`;

export const H1 = styled(posed.h1(slideInBottom))`
  text-transform: uppercase;
  font-size: 2.25;
  line-height: 1.05;
  font-family: var(--font-family-headings);

  @media (min-width: 530px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    margin-bottom: 3rem;
    font-size: 3rem;
  }
`;

export const H2 = styled(posed.h2(slideInBottom))`
  text-transform: uppercase;
  font-size: 2.25;
  line-height: 1.05;
  font-family: var(--font-family-headings);

  @media (min-width: 530px) {
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    margin-bottom: 3rem;
    font-size: 3rem;
  }
`;

export const Meta = styled(posed.p(slideInBottom))`
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 0.75rem;
  text-shadow: 3px 3px ${({ color }) => color};

  @media (min-width: 530px) {
    font-size: 0.65rem;
  }

  @media (min-width: 768px) {
    font-size: 0.75rem;
    letter-spacing: 3px;
  }
`;
