import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';
import posed from 'react-pose';
import { slideInBottom } from '../../utils/animation';

const Article = (props) => {
  const {
    body,
    className,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container
          ref={ref}
          pose={inView ? 'visible' : 'hidden'}
          className={className}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html,
          }}
        />
      )}
    </InView>
  );
};

const Container = styled(posed.div(slideInBottom))`
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 2rem;
  }
`;

export default Article;
