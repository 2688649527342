import React from 'react';
import styled from 'styled-components';
import { InView } from 'react-intersection-observer';

const Bar = (props) => {
  const {
    backgroundColor,
    textColor,
    className,
    children,
  } = props;

  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <Container
          ref={ref}
          pose={inView ? 'visible' : 'hidden'}
          className={className}
          backgroundColor={backgroundColor}
          textColor={textColor}
        >
          {children}
        </Container>
      )}
    </InView>
  );
};

const Container = styled.div`
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  font-size: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0.35rem 1rem 0.25rem;

  @media (min-width: 530px) {
    font-size: 0.65rem;
  }

  @media (min-width: 768px) {
    font-size: 0.75rem;
    letter-spacing: 3px;
    padding: 0.6rem 1rem 0.5rem;
    text-align: center;
  }
`;

export default Bar;
