import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';

import { pull } from '../utils/animation';
import { ArrowRight } from './svg-sprite';

const ArrowLink = React.forwardRef((props, ref) => {
  const {
    color,
    className,
    text,
    href,
  } = props;

  return (
    <a
      className={className}
      color={color}
      href={href}
      ref={ref}
    >
      <span>{text}</span>
      <ArrowRight color={color} />
    </a>
  );
});

export default styled(posed(ArrowLink)(pull))`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.65rem;
  line-height: 1.1;
  font-family: var(--font-family-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};

  &:hover {
    text-decoration: none;
    color: ${({ color }) => color};
  }

  span {
    margin-right: 0.5rem;
    margin-top: 2px;
  }

  svg {
    width: 33px;
    height: 13px;
  }

  @media (min-width: 768px) {
    font-size: 0.75rem;

    svg {
      width: 33px;
      height: 14px;
    }
  }
`;
